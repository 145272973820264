import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Skeleton } from '@mui/material';
import VideoPlayer from './VideoPlayer';
import { fetchVideoUrl } from '../../services/fetchVideoUrl';
import { REQUEST_STATUS } from '../../consts/requestStatus';
import DataErrorCard from '../common/DataErrorCard';
import InfoCard from '../common/InfoCard';


function VideoTab ({setVideoTimestamp, selectedVideoTimestamp}) {
    const { videoId } = useParams();
    const [videoUrl, setVideoUrl] = useState('');
    const [requestStatus, setRequestStatus] = useState(REQUEST_STATUS.LOADING);
    useEffect(() => {
        const getVideoUrl = async () => {
            const response = await fetchVideoUrl(videoId);
            setRequestStatus(response.requestStatus);
            setVideoUrl(response.videoUrl);
        }
        getVideoUrl();
    }, [videoId])
    if (requestStatus === REQUEST_STATUS.SUCCESS && videoUrl === '') {
        return (
            <InfoCard text="Video with Model Predictions was not generated for this inspection video." />
        )
    } else if (requestStatus === REQUEST_STATUS.SUCCESS) {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }} >
                <VideoPlayer videoUrl={videoUrl} setVideoTimestamp={setVideoTimestamp} selectedVideoTimestamp={selectedVideoTimestamp}/>
            </div>
        );
    } else if (requestStatus === REQUEST_STATUS.LOADING) {
        return (
            <div style={{ maxWidth: '100%', maxHeight: '100%', overflow: 'auto', padding: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Skeleton variant="rectangular" height={450} width={800}/>
            </div>
        )
    } else {
        return (
            <DataErrorCard maxWidth={400} text='An error occurred while loading video. Please try again later or contact the support team.'/>
        )
    }
    
}

export default VideoTab;