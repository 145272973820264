import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import { Card, CardContent, Typography, Box } from '@mui/material';
import CustomTable from '../common/CustomTable';
import DataErrorCard from '../common/DataErrorCard';

function SevereTable({ data }) {
    const columns = [
        {id: 'street', label: 'Street Name'},
        {id: 'defectCount', label: 'Total Defects'},
        {id: 'severePipes', label: 'Severe Pipes'},
        {id: 'pipesInspected', label: 'Pipes Inspected'}
    ]
    if (data === null) {
        return (
            <Card sx={{ 
                boxShadow: 3,
                mx: 'auto',
                width: '100%',
                minWidth: 400,
              }}>
                <CardContent>
                    <DataErrorCard/>
                </CardContent>
            </Card>
        )
    }
    return (
        <Card sx={{ 
            boxShadow: 3,
            mx: 'auto',
            width: '100%',
            minWidth: 400,
          }}>
            <CardContent>
                <Box sx={{display:'flex', flexDirection: 'row'}}>
                    <Typography variant="h6" color="textSecondary" sx={{ mb: 3 }}>
                        Top Streets by Defect Count
                    </Typography>
                    <Box sx={{flex: '1 1 auto'}} />
                    <Link
                        underline="hover"
                        component={RouterLink}
                        to={`/inspections`}
                    >
                        See all
                    </Link>
                </Box>
                
                <CustomTable
                    columns={columns}
                    data={data}
                    rowKey={"Id"}
                    tableMinWidth={300}
                    container={Box}
                />
            </CardContent>
        </Card>
    );
}

export default SevereTable;