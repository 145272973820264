import React from 'react';
import { Typography, List, ListItemText, ListItem, Box } from '@mui/material';
import InspectionMap from '../PipeLocation/InspectionMap';

function VideoDetailsTab({videoInfo, textStyle, severityScore}) {
    if (videoInfo !== null) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                <List sx={{ flex: 1 }}>
                    {Object.entries(videoInfo).map(([key, value]) => (
                        <ListItem key={key} disablePadding>
                            <ListItemText>
                                <Typography variant="subtitle1" sx={textStyle}><strong>{key}:</strong> {value}</Typography>
                            </ListItemText>
                        </ListItem>
                    ))}
                </List>
                <Box sx={{ flexShrink: 0 }}>
                    <InspectionMap severityScore={severityScore}/>
                </Box>
            </Box>
        )
    } else {
        return null;
    }
    
}

export default VideoDetailsTab;