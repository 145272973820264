import DatasetIcon from '@mui/icons-material/Dataset';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import AssessmentIcon from '@mui/icons-material/Assessment';

export const mainNavbarItems = [
    {
        id: 0,
        icon: <AssessmentIcon />,
        label: 'Dashboard',
        route: 'dashboard'
    },
    {
        id: 0,
        icon: <DatasetIcon />,
        label: 'Inspections',
        route: 'inspections'
    },
    {
        id: 1,
        icon: <CloudUploadIcon />,
        label: 'Upload Video',
        route: 'upload-video'
    }
]