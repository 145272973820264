import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, FormControlLabel, Checkbox, Box, Typography, CircularProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import downloadResults from '../../services/downloadResults';
import { REQUEST_STATUS } from '../../consts/requestStatus';

function DownloadDialog({open, setClose, videoName, hasVideoPredictionsPath}) {
    const [errorMessages, setErrorMessages] = useState([]);
    const [requestStatus, setRequestStatus] = useState(REQUEST_STATUS.SUCCESS);
    const { videoId } = useParams();
    const [checkboxes, setCheckboxes] = useState({
        videoWithPredictions: false,
        predictions: false,
        videoMetadata: false,
        report: false,
    });

    const handleCheckboxChange = (event) => {
        setCheckboxes({ ...checkboxes, [event.target.name]: event.target.checked });
    };

    const resetCheckboxes = () => {
        setCheckboxes({
            videoWithPredictions: false,
            predictions: false,
            videoMetadata: false,
            report: false,
        });
        setErrorMessages([]);
    }

    const handleDownload = async () => {
        setRequestStatus(REQUEST_STATUS.LOADING);
        const errors = await downloadResults({ 
            videoId: videoId,
            videoName: videoName,
            videoWithPredictions: checkboxes.videoWithPredictions,
            predictions: checkboxes.predictions,
            videoMetadata: checkboxes.videoMetadata,
            report: checkboxes.report
        });
        if (errors.length > 0) {
            setErrorMessages(errors);
            setRequestStatus(REQUEST_STATUS.FAILURE);
        } else {
            setClose();
            resetCheckboxes();
            setRequestStatus(REQUEST_STATUS.SUCCESS);
        }
    };

    const onClose = () => {
        setClose();
        resetCheckboxes();
        setRequestStatus(REQUEST_STATUS.SUCCESS);
    }
    const theme = useTheme(); 
    return(
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>What do you want to download?</DialogTitle>
            <DialogContent>
                {requestStatus === REQUEST_STATUS.LOADING && ( // Add the overlay when loading
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            zIndex: 1,
                            backdropFilter: 'blur(3px)', // Blur effect
                        }}
                    />
                )}
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <FormControlLabel
                        control={<Checkbox checked={checkboxes.report} onChange={handleCheckboxChange} name="report" />}
                        label="Report"
                    />
                    <FormControlLabel
                        control={<Checkbox checked={checkboxes.predictions} onChange={handleCheckboxChange} name="predictions" />}
                        label="Model Predictions"
                    />
                    <FormControlLabel
                        control={<Checkbox checked={checkboxes.videoMetadata} onChange={handleCheckboxChange} name="videoMetadata" />}
                        label="Video Metadata"
                    />
                    
                    <FormControlLabel
                        control={
                            <Checkbox 
                                checked={checkboxes.videoWithPredictions} 
                                onChange={handleCheckboxChange} 
                                name="videoWithPredictions" 
                                disabled={!hasVideoPredictionsPath} // Disable if hasVideoPredictionsPath is false
                            />
                        }
                        label="Video with Predictions"
                    />
                    {!hasVideoPredictionsPath && ( // Display message if not available
                        <Typography variant="subtitle2" style={{ color: theme.palette.action.disabled }}>
                            <b>Not available for this video</b> <br/>
                            Video with Predictions is not yet available for videos larger than 50 MB.
                        </Typography>
                    )}
                    
                    
                </Box>
                {errorMessages.length > 0 && (
                <Box 
                    mt={2} 
                    p={2} 
                    border={1} 
                    borderColor="error.main" 
                    borderRadius={1}
                    sx={{ wordWrap: 'break-word', maxWidth: '100%' }}
                >
                    {errorMessages.map((msg, index) => (
                        <Typography key={index} variant="body2" color="error">
                            {msg}
                        </Typography>
                    ))}
                    <Typography variant="body2" color="error">
                        Please try to download later.
                    </Typography>
                </Box>
                )}
                {requestStatus === REQUEST_STATUS.LOADING && ( // Show loading spinner while downloading
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            zIndex: 2,
                        }}
                    >
                        <CircularProgress />
                    </Box>
                )}
            </DialogContent>
            
            <DialogActions>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <Button 
                        onClick={onClose} 
                        color="primary" 
                        sx={{ml: 1}}
                        disabled={requestStatus === REQUEST_STATUS.LOADING}
                    >
                        Cancel
                    </Button>
                    <Button 
                        onClick={handleDownload} 
                        color="primary" 
                        sx={{mr: 1}}
                        disabled={requestStatus === REQUEST_STATUS.LOADING}
                    >
                        Download
                    </Button>
                </Box>
                
            </DialogActions>
        </Dialog>
    )
}

export default DownloadDialog;