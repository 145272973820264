import React, { useCallback, useRef, useEffect, useState } from 'react';
import { throttle } from 'lodash';

function VideoPlayer({ videoUrl, setVideoTimestamp, selectedVideoTimestamp }) {
  const videoRef = useRef(null);
  const [isReady, setIsReady] = useState(false);

  const handleCanPlay = () => {
    setIsReady(true); // Set video as ready once it's loaded
  };
  useEffect(() => {
    if (isReady && videoRef.current && !isNaN(selectedVideoTimestamp)) {
      videoRef.current.currentTime = selectedVideoTimestamp; // Seek to the timestamp
      videoRef.current.play(); // Start playing the video
    }
  }, [selectedVideoTimestamp, isReady]);
  const handleTimeUpdate = useCallback(
    throttle((event) => {
      setVideoTimestamp(event.target.currentTime);
    }, 500),
    []
  );

  return (
    <div
      style={{
        width: 'calc(100% - 40px)',
        maxWidth: '800px',
        aspectRatio: '16 / 9',
        backgroundColor: '#000',
        position: 'relative',
        margin: '20px auto',
      }}
    >
      <video
        ref={videoRef}
        controls
        onCanPlay={handleCanPlay}
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
          objectFit: 'contain',
        }}
        onTimeUpdate={handleTimeUpdate}
        onError={(e) => console.error('Video Error:', e)}
      >
        <source src={videoUrl} />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default VideoPlayer;