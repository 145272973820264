import { useState } from "react";
import { Tabs, Tab, Paper, Box } from "@mui/material";
import { StatusTableProvider } from "../contexts/StatusTableContext";
import TableSettings from "../components/StatusTable/TableSettings";
import StatusTable from "../components/StatusTable/StatusTable";
import MapOverviewTab from "../components/PipeLocation/MapOverviewTab";

function InspectionsPage() {
    const [selectedTab, setSelectedTab] = useState(0);
        const handleTabChange = (event, newValue) => {
            setSelectedTab(newValue);
        };
    return (
        <StatusTableProvider>
            <TableSettings/>
            <Paper>
            <Tabs value={selectedTab} onChange={handleTabChange} centered aria-label="Overview tabs">
                <Tab label="Table" />
                <Tab label="Map" />
            </Tabs>
            {selectedTab === 0 ? (
                <StatusTable />
            ): (
                <Box sx={{padding: '20px'}}>
                <MapOverviewTab/>
                </Box>
            )}
            
            </Paper>
        </StatusTableProvider>
    )
}

export default InspectionsPage;