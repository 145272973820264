import React from 'react';
import {
  Box,
  Divider,
} from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import CardText from './CardText';
import CardTitle from './CardTitle';
import CardValue from './CardValue';
import DataErrorCard from '../../common/DataErrorCard';

function SeverePipes({severeConditionPipes, severePercentage}) {
    if (severeConditionPipes === null || severePercentage === null) {
        return (
            <DataErrorCard/>
        )
    }
    return (
        <>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <WarningIcon sx={{ color: 'error.dark', mr: 1, fontSize: 28 }} />
            <CardTitle text={'Pipes in severe condition'}/>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
            <CardValue value={severeConditionPipes} color='error.dark'/>
        </Box>
        <Divider sx={{ my: 1.5 }} />
        <CardText text={`${severePercentage}% of inspected pipes require immediate attention`}/>
        </>
    )
}

export default SeverePipes;