import { PieChart, Pie, Cell, ResponsiveContainer, Legend, Tooltip, Label } from 'recharts';
import React, {useState} from 'react';
import { 
  Card, 
  CardContent, 
  Typography, 
  Box,
  useTheme
} from '@mui/material';
import DataErrorCard from '../common/DataErrorCard';

function CustomLabel({viewBox, totalDefects}){
    const {cx, cy} = viewBox;
    return (
     <text x={cx} y={cy} fill="#3d405c" className="recharts-text recharts-label" textAnchor="middle" dominantBaseline="central">
        <tspan alignmentBaseline="middle" fontSize="26">{totalDefects}</tspan>
     </text>
    )
}

function DefectsPieChart({defectTypes}) {
    
    const [activeIndex, setActiveIndex] = useState(-1);
    const theme = useTheme();
    const onPieEnter = (_, index) => {
        setActiveIndex(index);
    };
    const pieChartColors = {
      'BAB': theme.palette.primary.main,
      'BAF': theme.palette.secondary.main,
      'BBF': theme.palette.error.main
    }
    if (defectTypes === null) {
      return (
        <Card sx={{ 
          boxShadow: 3,
          mx: 'auto',
          width: '100%',
          minWidth: 400,
        }}>
          <CardContent>
            <DataErrorCard/>
          </CardContent>
        </Card>
      )
    }
    const totalDefects = defectTypes.reduce((sum, type) => sum + type.value, 0);
    return (
        <>
            <Card sx={{ 
              boxShadow: 3,
              mx: 'auto',
              width: '100%',
              minWidth: 400,
            }}>
              <CardContent>
                <Typography variant="h6" color="textSecondary" sx={{ mb: 3 }}>
                  Defect Type Distribution
                </Typography>
                <Box sx={{ height: 200 }}>
                  <ResponsiveContainer width="100%" height="100%">
                    <PieChart>
                        <Pie
                            isAnimationActive={false}
                            activeIndex={activeIndex}
                            data={defectTypes}
                            dataKey="value"
                            innerRadius={60}
                            outerRadius={100}
                            paddingAngle={3}
                            fill="green"
                            onMouseEnter={onPieEnter}
                            style={{ cursor: 'pointer', outline: 'none' }} // Ensure no outline on focus
                        >
                            {defectTypes.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={pieChartColors[entry.name]} />
                            ))}
                            <Label position="center" content={<CustomLabel totalDefects={totalDefects}/>}>
                            </Label>
                        </Pie>
                        <Tooltip 
                        formatter={(value, name, entry) => {
                            return [`${value} defects (${((value / totalDefects) * 100).toFixed(1)}%)`, `${entry.payload.name}`];
                        }}
                        />
                        <Legend
                            formatter={(value, entry, index) => {
                                const item = defectTypes[index];
                                const percentage = ((item.value / totalDefects) * 100).toFixed(1);
                                return (
                                  <Typography 
                                    variant="body2" 
                                    display="inline"
                                    color="textPrimary"
                                  >
                                    {value} • {item.value} ({percentage}%)
                                  </Typography>
                                );
                              }}
                            layout="vertical"
                            verticalAlign="middle"
                            align="right"
                        />
                    </PieChart>
                  </ResponsiveContainer>
                </Box>
              </CardContent>
            </Card>
          </>
    )
}

export default DefectsPieChart;