import { useContext } from "react";
import { CircularProgress } from "@mui/material";
import { StatusTableContext } from "../../contexts/StatusTableContext";
import { getMapData } from "../../utils/statusTableUtils";
import { REQUEST_STATUS } from "../../consts/requestStatus";
import OverviewMap from "./OverviewMap";
import EmptyMapCard from "./EmptyMapCard";
import DataErrorCard from "../common/DataErrorCard";
import MapPlaceholder from "./MapPlaceholder";

function MapOverviewTab() {
    const {
            data,
            tableRequestStatus, 
            curSelectedStatuses, curTextSearch, curDateSearch,  
    } = useContext(StatusTableContext);
    
    const mapData = getMapData(data, curSelectedStatuses, curTextSearch, curDateSearch);
    if (tableRequestStatus === REQUEST_STATUS.SUCCESS) {
        if (mapData.length > 0) {
            return <OverviewMap data={mapData}/>
        } else {
            return (
            <MapPlaceholder
                height="60vh" 
                width="auto" 
                maxHeight="75vh" 
                minHeight="50vh"
            >
                <EmptyMapCard customText={'There are currently no pipes available to display on the map.'}/>
            </MapPlaceholder>
            )
        }
    } else if (tableRequestStatus === REQUEST_STATUS.LOADING) {
        return (
            <MapPlaceholder
                height="60vh" 
                width="auto" 
                maxHeight="75vh" 
                minHeight="50vh"
            >
                <CircularProgress />
            </MapPlaceholder>
        )
    } else {
        return (
            <MapPlaceholder
                height="60vh" 
                width="auto" 
                maxHeight="75vh" 
                minHeight="50vh"
            >
                <DataErrorCard transparent={true}/>
            </MapPlaceholder>
        )
    }
}
export default MapOverviewTab;