import React from 'react';
import { 
  Grid,
  Box,
  CircularProgress
} from '@mui/material';
import KpiCards from '../components/Dashboard/KpiCards/KpiCards';
import DefectsPieChart from '../components/Dashboard/DefectsPieChart';
import InspectionsChart from '../components/Dashboard/InspectionsChart';
import useRequestDashboard from '../hooks/useRequestDashboard';
import { REQUEST_STATUS } from '../consts/requestStatus';
import DataErrorCard from '../components/common/DataErrorCard';
import SevereTable from '../components/Dashboard/SevereTable';

function AnalyticsPage() {
    const { data, requestStatus } = useRequestDashboard();
    if (requestStatus === REQUEST_STATUS.LOADING) {
      return (
        <div style={{ textAlign: 'center', padding: '20px' }}>
            <CircularProgress />
        </div>
      )
    } else if (requestStatus === REQUEST_STATUS.FAILURE) {
      return <DataErrorCard />
    } else {
    return (
      <Box sx={{ padding: 3 }}>
        <Grid 
          container 
          spacing={3}
          sx={{
            justifyContent: "center"
            // alignItems: "flex-start",
          }}
        >
          <Grid container item xs={12} sm={12} md={12} lg={12} xl={7} spacing={3}>
            <KpiCards analyticsData={data}/>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={5}>
            <DefectsPieChart defectTypes={data.defectTypes}/>
          </Grid>
          <Grid item xs={12} xl={6}>
            <InspectionsChart data={data.inspectionsByMonth}/>
          </Grid>
          <Grid item xs={12} xl={6}>
            <SevereTable data={data.topDefectStreets}/>
          </Grid>
        </Grid>
      </Box>
      )
    }
}

export default AnalyticsPage;