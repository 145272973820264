import React from "react";

const SeverityScoreCard = ({severityScore}) => {
    const severityStyle = {
        background: severityScore >= 8 ? 
            'linear-gradient(to right, #ff4d4f, #ff7875)' : 
            severityScore >= 5 ? 
            'linear-gradient(to right, #faad14, #ffc53d)' : 
            'linear-gradient(to right, #52c41a, #87d068)',
        color: 'white',
        padding: '6px 12px',
        borderRadius: '6px',
        display: 'inline-block',
        marginLeft: '10px',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
    };
    if (severityScore){
        return (
            <span style={severityStyle}>{severityScore}</span>
        )
    } else {
        return null;
    }
}
    

export default SeverityScoreCard;