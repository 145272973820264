import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import StatCard from "./StatCard";
import SeverityScoreCard from './SeverityScoreCard';


const StatusCards = ({ labelsStat, setSelectedLabel, severityScore }) => {
    if (labelsStat != null) {
        return (
            <Box sx={{ paddingTop: '30px', ml: '10px' }}>
                {severityScore && (
                    
                    <Typography variant="h6" sx={{ mb: 3, display: 'flex', alignItems: 'center' }}>
                        Severity Score: 
                        <SeverityScoreCard severityScore={severityScore} />
                    </Typography>

                )}
                
                <Grid container justifyContent="left" spacing={2}>
                    {labelsStat.map((item, index) =>
                    Object.entries(item).map(([defect, value]) =>
                        defect !== "Total" ? (
                            <Grid item key={defect}>
                                <StatCard defect={defect} value={value} setSelectedLabel={setSelectedLabel}/>
                            </Grid>
                        ) : null
                    )
                    )}
                </Grid>
            </Box>
          );
    } else {
        return null;
    }
    
};

export default StatusCards;