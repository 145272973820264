import { Typography } from "@mui/material"

function CardSecondaryValue({value}) {
    return (
        <Typography variant="h6" sx={{ fontWeight: 'medium' }} color="textSecondary">
            {value}
        </Typography>
    )
}

export default CardSecondaryValue;