const sliceData = (data, page, nRows) => {
    if (!Array.isArray(data) || data.length === 0) return [];
    if (page < 1 || nRows < 1) return [];

    const startIndex = (page - 1) * nRows;
    const endIndex = startIndex + nRows;
    return data.slice(startIndex, endIndex);
}

const filterByStatus = (data, selectedStatuses) => {
    if (selectedStatuses.length === 0) {
        return data;
    }
    return data.filter(item => selectedStatuses.includes(item.Status));
}

const filterByTextSearch = (data, textSearch) => {
    return data.filter(item => {
        return Object.entries(textSearch).every(([key, value]) => {
            const trimmedValue = value.trim(); // Trim whitespace
            if (!trimmedValue) return true; // Ignore empty or whitespace-only values
            return item[key]?.toString().toLowerCase().includes(trimmedValue.toLowerCase());
        });
    });
};

const filterByDateSearch = (data, dateSearch) => {
    return data.filter(item => {
        return Object.entries(dateSearch).every(([key, { startDate, endDate }]) => {
            const itemDateStr = item[key]?.trim(); // Trim whitespace

            const hasStartDate = startDate.trim() !== '';
            const hasEndDate = endDate.trim() !== '';

            // If no filtering is applied, keep all rows (including empty dates)
            if (!hasStartDate && !hasEndDate) return true; 

            // If filtering is applied and the date field is empty, exclude this row
            if (!itemDateStr) return false;

            const itemDate = new Date(itemDateStr);
            if (isNaN(itemDate.getTime())) return false; // Exclude invalid dates

            const start = hasStartDate ? new Date(startDate) : null;
            const end = hasEndDate ? new Date(endDate) : null;

            if (hasStartDate && isNaN(start.getTime())) return false; // Invalid start date
            if (hasEndDate && isNaN(end.getTime())) return false; // Invalid end date

            if (start && end) {
                return itemDate >= start && itemDate <= end; // Between startDate and endDate
            } else if (start) {
                return itemDate >= start; // Only startDate is provided
            } else if (end) {
                return itemDate <= end; // Only endDate is provided
            }

            return true;
        });
    });
};

const sortData = (data, tableOrder) => {
    const { orderBy, mode } = tableOrder;

    if (!orderBy || !mode || !['ASC', 'DESC'].includes(mode) || !Array.isArray(data)) {
        return data; // Return original data if sorting is not properly defined
    }

    return [...data].sort((a, b) => {
        let aValue = a[orderBy];
        let bValue = b[orderBy];

        // Handle empty values (null, undefined, or empty string)
        const isAEmpty = aValue == null || aValue === "";
        const isBEmpty = bValue == null || bValue === "";

        if (isAEmpty && isBEmpty) return 0; // Keep relative order if both are empty
        if (isAEmpty) return mode === 'ASC' ? 1 : -1; // Empty values go last in ASC, first in DESC
        if (isBEmpty) return mode === 'ASC' ? -1 : 1;

        // Handle numbers
        if (typeof aValue === 'number' && typeof bValue === 'number') {
            return mode === 'ASC' ? aValue - bValue : bValue - aValue;
        }

        // Convert to strings for comparison
        const aStr = String(aValue).toLowerCase();
        const bStr = String(bValue).toLowerCase();

        if (aStr < bStr) return mode === 'ASC' ? -1 : 1;
        if (aStr > bStr) return mode === 'ASC' ? 1 : -1;
        return 0;
    });
};

const filterByLocation = (data) => {
    return data.filter(item => item.StartPointLocation != null && item.EndPointLocation != null);
};

export const getMapData = (
    data,
    selectedStatuses,
    textSearch,
    dateSearch
) => {
    let filteredData = filterByStatus(data, selectedStatuses);
    filteredData = filterByTextSearch(filteredData, textSearch);
    filteredData = filterByDateSearch(filteredData, dateSearch);
    filteredData = filterByLocation(filteredData);
    return filteredData
}

export const getStatusTableData = (
    data,
    page,
    nRows,
    selectedStatuses,
    tableOrder,
    textSearch,
    dateSearch
) => {
    let filteredData = filterByStatus(data, selectedStatuses);
    filteredData = filterByTextSearch(filteredData, textSearch);
    filteredData = filterByDateSearch(filteredData, dateSearch);
    const totalRows = filteredData.length;
    const sortedData = sortData(filteredData, tableOrder);
    const tableData = sliceData(sortedData, page, nRows);
    
    return {
        totalRows: totalRows,
        tableData: tableData,
    };
}