import React from 'react';
import VideoDetailsTab from './VideoDetailsTab';
import MetadataTab from './MetadtaTab';
import MonitoringTab from './MonitoringTab';
import StatusCardsEmpty from './StatusCardsEmpty';

function TabsContentNotEvaluated ({
    selectedOverviewTab,
    videoInfo,
    monitoring,
    textStyle,
    severityScore
}) {
    switch (selectedOverviewTab) {
        case 0:
            return (
                <VideoDetailsTab
                    videoInfo={videoInfo}
                    textStyle={textStyle}
                    severityScore={severityScore}
                />
            );
        case 1:
            
            return (
                <MonitoringTab
                monitoring={monitoring}
                />
            );
        case 2:
            return (
                <MetadataTab />
            );
        case 3:
            return (
                <StatusCardsEmpty videoInfo={videoInfo}/>
            )
        default:
            return null;
    }
}

export default TabsContentNotEvaluated;