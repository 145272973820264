import React from 'react';
import {
  Box,
  Divider,
  LinearProgress
} from '@mui/material';
import AssignmentIcon from '@mui/icons-material/Assignment';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CardText from './CardText';
import CardSecondaryValue from './CardSecondaryValue';
import CardTitle from './CardTitle';
import CardValue from './CardValue';
import DataErrorCard from '../../common/DataErrorCard';

function TotalInspections({totalInspections, evaluatedInspections}) {
    if (totalInspections === null || evaluatedInspections === null) {
        return (
            <DataErrorCard/>
        )
    }
    const evaluatedPercentage = Math.round(evaluatedInspections / (totalInspections / 100))
    return (
        <>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <AssignmentIcon sx={{ color: 'primary.main', mr: 1, fontSize: 28 }} />
            <CardTitle text={'Total Inspections'}/>
        </Box>
        <CardValue value={totalInspections}/>
        <Divider sx={{ my: 1.5 }} />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <CheckCircleIcon sx={{ color: 'success.main', mr: 1, fontSize: 20 }} />
                <CardText text={'Evaluated'}/>
            </Box>
            <CardSecondaryValue value={`${evaluatedInspections} / ${totalInspections}`}/>
        </Box>
        <LinearProgress 
            variant="determinate" 
            value={evaluatedPercentage}
            color="success"
            sx={{ 
                height: 8, 
                borderRadius: 1,
                mb: 1
            }} 
        />
        </>
    )
}

export default TotalInspections;