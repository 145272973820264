import { Button, Card, CardContent, Typography } from "@mui/material";
import React, { useContext } from "react";
import { AuthContext } from "../contexts/AuthContext";
import Logo from '../asserts/Ireckon_City_Logo_Files-01-cropped.svg';

function LoginPage() {
    const { userInfo, handleLogin } = useContext(AuthContext);
    const handleContinue = () => {
        window.location.href = `/dashboard`
    };

    if (userInfo) {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh', backgroundColor: '#f5f5f5', gap: '20px', padding: '20px' }}>
            <Card variant="outlined" style={{ padding: '20px', textAlign: 'center' }}>
                <CardContent>
                    <img src={Logo} alt="Ireckon City Logo" style={{ width: '150px', marginBottom: '20px' }} />
                    <Typography variant="h5" component="div">
                        Welcome to Sewer Object Detection Platform!
                    </Typography>
                    <Typography variant="body1" color="text.secondary" style={{ margin: '20px 0' }}>
                        {`Hello, ${userInfo.userDetails}`}
                    </Typography>
                    <Button 
                        variant="contained" 
                        color="primary" 
                        onClick={handleContinue}
                    >
                        Continue
                    </Button>
                </CardContent>
            </Card>
            </div>
        )
    }
    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh', backgroundColor: '#f5f5f5', gap: '20px', padding: '20px' }}>
            <Card variant="outlined" style={{ padding: '20px', textAlign: 'center' }}>
                <CardContent>
                    <img src={Logo} alt="Ireckon City Logo" style={{ width: '150px', marginBottom: '20px' }} />
                    <Typography variant="h5" component="div">
                        Welcome to Sewer Object Detection Platform!
                    </Typography>
                    <Typography variant="body1" color="text.secondary" style={{ margin: '10px 0' }}>
                        If you're new here and would like to learn more or book a demo, please contact us at <a href="mailto:quinten@ireckoncity.com">quinten@ireckoncity.com</a>.
                    </Typography>
                    <Typography variant="body1" color="text.secondary" style={{ margin: '20px 0' }}>
                        If you're an existing customer, please log in below.
                    </Typography>
                    <Button 
                        variant="contained" 
                        color="primary" 
                        onClick={handleLogin}
                    >
                        Login
                    </Button>
                </CardContent>
            </Card>
        </div>
    )
}

export default LoginPage;