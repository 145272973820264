import React, { createContext, useState, useEffect } from "react";
import useRequestStatusTable from "../hooks/useRequestStatusTable";
import { REQUEST_STATUS } from "../consts/requestStatus";

export const StatusTableContext = createContext();

function StatusTableProvider({ 
    children, 
    startingNumberRows = 10,
    startingPage = 1,
    startingStatus = [],
    startingOrder = {orderBy: "Id", mode: "DESC"},
    startingTextSearch = {"Id": "", "Name": "", "Username": "", "Place": "", "StreetName": ""},
    startingDateSearch = {"UploadTime": {"startDate":"","endDate":""}, "InspectionDate": {"startDate":"","endDate":""}}
 }) {
    const [nRows, setNRows] = useState(startingNumberRows);
    const [page, setPage] = useState(startingPage);
    const [ tableOrder, setTableOrder ] = useState(startingOrder);

    const [selectedStatuses, setSelectedStatuses] = useState(startingStatus);
    const [curSelectedStatuses, setCurSelectedStatuses] = useState(startingStatus);
    const [ textSearch, setTextSearch ] = useState(startingTextSearch);
    const [ curTextSearch, setCurTextSearch ] = useState(startingTextSearch);
    const [ dateSearch, setDateSearch ] = useState(startingDateSearch);
    const [ curDateSearch, setCurDateSearch ] = useState(startingDateSearch);
    const [ searchDisabled, setSearchDisabled ] = useState(false);

    const { data, tableRequestStatus, statusOptions, refreshTable } = useRequestStatusTable();
    const searchAction = () => {
        setCurSelectedStatuses(selectedStatuses);
        setCurTextSearch(textSearch);
        setCurDateSearch(dateSearch);
        refreshTable();
    }

    const resetSearch = () => {
        setSelectedStatuses(startingStatus);
        setTableOrder(startingOrder);
        setTextSearch(startingTextSearch);
        setDateSearch(startingDateSearch);
        setSearchDisabled(false);
    }

    useEffect(() => {
        searchAction();
    }, [tableOrder])

    useEffect(() => {
        if (tableRequestStatus === REQUEST_STATUS.LOADING) {
            setSearchDisabled(true);
        } else {
            setSearchDisabled(false);
        }
    }, [tableRequestStatus])
    
    return (
        <StatusTableContext.Provider
            value={{ 
                nRows, setNRows,
                page, setPage,
                selectedStatuses, setSelectedStatuses, curSelectedStatuses,
                statusOptions,
                data, refreshTable, tableRequestStatus,
                tableOrder, setTableOrder,
                textSearch, setTextSearch, curTextSearch,
                dateSearch, setDateSearch, curDateSearch,
                searchDisabled, setSearchDisabled,
                resetSearch, searchAction
            }}
        >
            { children }
        </StatusTableContext.Provider>
    )
}

export { StatusTableProvider };