import React from 'react';
import { Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';

function StatusCardsEmpty({videoInfo}) {
    if (videoInfo.Status === 'Evaluating') {
        return (
            <Typography variant="body1" mt={5}> The evaluation of this video is in progress. Come back later! </Typography>
        )
    } else {
        return (
            <Typography variant="body1" mt={5}>
                This video has not been evaluated yet. <br />
                To get results, please trigger the evaluation{' '}
                <Link
                    underline="hover"
                    component={RouterLink}
                    to="/inspections"
                >
                    here
                </Link>.
            </Typography>
        )
    }
}

export default StatusCardsEmpty;