import React from 'react';
import {
  Box,
  Divider
} from '@mui/material';
import BugReportIcon from '@mui/icons-material/BugReport';
import CardText from './CardText';
import CardSecondaryValue from './CardSecondaryValue';
import CardTitle from './CardTitle';
import CardValue from './CardValue';
import DataErrorCard from '../../common/DataErrorCard';


function TotalDefects({totalDefects, defectsPerInspection}) {
    if (totalDefects === null || defectsPerInspection === null) {
        return (
            <DataErrorCard/>
        )
    }
    return (
        <>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <BugReportIcon sx={{ color: 'error.main', mr: 1, fontSize: 28 }} />
                <CardTitle text={'Defects Detected'}/>
            </Box>
            <CardValue value={totalDefects}/>
            <Divider sx={{ my: 1.5 }} />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <CardText text={'Average per inspection:'}/>
                <CardSecondaryValue value={defectsPerInspection}/>
            </Box>
            
        </>
    )
}

export default TotalDefects;