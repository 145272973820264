import { Paper } from '@mui/material';
import MapImage from '../../asserts/map_placeholder.jpg';

function MapPlaceholder({children, height, width, maxHeight="none", minHeight="none"}) {
    return (
        <Paper
            elevation={3}
            sx={{
                height,
                width,
                maxHeight,
                minHeight,
                borderRadius: '8px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                border: '1px dashed #ccc',
                borderRadius: 2,
                backgroundColor: '#f9f9f9',
                padding: 2,
                boxSizing: 'border-box',
                textAlign: 'center',
                position: 'relative',
                overflow: 'hidden',
                backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), url(${MapImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
            }}
        >
            {children}
        </Paper>
    );
}

export default MapPlaceholder;