import React, { useState, useEffect } from 'react';
import MediaTabs from '../VideoMediaTabs/MediaTabs';
import DetectionsGallery from './DetectionsGallery';
import DetectionsTable from './DetectionsTable';

function VideoDetections({
    data, 
    labels, 
    selectedLabel, setSelectedLabel, 
    fpsValue,
}) {
    const [imageLocation, setImageLocation] = useState(null);
    const [galleryOpen, setGalleryOpen] = useState(false);
    const [galleryRow, setGalleryRow] = useState(null);
    const [videoTimestamp, setVideoTimestamp] = useState(null);
    const [selectedTab, setSelectedTab] = useState(0);
    const [selectedVideoTimestamp, setSelectedVideoTimestamp] = useState(0);
    const [imageTimestamp, setImageTimestamp] = useState(0);
    useEffect(() => {
        if (selectedTab === 0) {
            setSelectedVideoTimestamp(0);
        }
    }, [selectedTab])
    return (
      <div>
        <DetectionsGallery
            galleryOpen={galleryOpen}
            setGalleryOpen={setGalleryOpen}
            galleryRow={galleryRow}
        />
        {data !== null && (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <DetectionsTable
                    data={data}
                    labels={labels}
                    selectedLabel={selectedLabel} setSelectedLabel={setSelectedLabel}
                    fpsValue={fpsValue}
                    setImageLocation={setImageLocation}
                    setGalleryOpen={setGalleryOpen}
                    setGalleryRow={setGalleryRow}
                    videoTimestamp={videoTimestamp}
                    selectedTab={selectedTab} setSelectedTab={setSelectedTab}
                    setSelectedVideoTimestamp={setSelectedVideoTimestamp}
                    setImageTimestamp={setImageTimestamp}
                />
                <MediaTabs
                    imageLocation={imageLocation}
                    setVideoTimestamp={setVideoTimestamp}
                    selectedTab={selectedTab} setSelectedTab={setSelectedTab}
                    selectedVideoTimestamp={selectedVideoTimestamp} setSelectedVideoTimestamp={setSelectedVideoTimestamp}
                    imageTimestamp={imageTimestamp}
                />
            </div>
        )}
        
      </div>
    );
};

export default VideoDetections;
