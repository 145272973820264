export const calculateCenter = (coordinates) => {
    const latitudes = coordinates.map(point => point[0]);
    const longitudes = coordinates.map(point => point[1]);
    const centerLat = (Math.min(...latitudes) + Math.max(...latitudes)) / 2;
    const centerLng = (Math.min(...longitudes) + Math.max(...longitudes)) / 2;
    return [centerLat, centerLng];
};

export const getMapLineOptions = (severityScore) => {
    let color = '#D400FF'; // Neon Magenta (default)
    if (severityScore !== null) {
        if (severityScore >= 1 && severityScore <= 4) color = '#00C800'; // Neon Green
        else if (severityScore >= 5 && severityScore <= 7) color = '#ff5722'; // Bright Orange
        else if (severityScore >= 8 && severityScore <= 10) color = '#FF0033'; // Neon Red
    }
    return { color, weight: 10, opacity: 0.7 };
}