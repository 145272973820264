import { useState, useEffect } from 'react';
import { REQUEST_STATUS } from '../consts/requestStatus';

function useRequestDashboard() {
    const [data, setData] = useState(null);
    const [requestStatus, setRequestStatus] = useState(REQUEST_STATUS.LOADING);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const requestUrl = process.env.REACT_APP_GET_DASHBOARD_DATA;
                const response = await fetch(requestUrl);
                if (response.ok) {
                    const resultString = await response.text();
                    const resultData = JSON.parse(resultString);
                    const defectsPerInspection = 
                        resultData.totalDefects && resultData.evaluatedInspections 
                            ? (resultData.totalDefects / resultData.evaluatedInspections).toFixed(1) 
                            : null;
                    const severePercentage = 
                        resultData.severeConditionPipes && resultData.evaluatedInspections
                            ? ((resultData.severeConditionPipes / resultData.evaluatedInspections) * 100).toFixed(1)
                            : null;
                    setData({ 
                        ...resultData, 
                        defectsPerInspection,
                        severePercentage
                    });
                    setRequestStatus(REQUEST_STATUS.SUCCESS);
                } else {
                    console.error('Failed to fetch dashboard data:', response.status);
                    setRequestStatus(REQUEST_STATUS.FAILURE);
                }
            } catch (error) {
                console.error('Error fetching dashboard data:', error.message);
                setRequestStatus(REQUEST_STATUS.FAILURE);
            }
        };
        fetchData();
    }, []);
    return {
        data,
        requestStatus
    }
}

export default useRequestDashboard;