import React from 'react';
import StatusCards from './StatusCards';
import VideoDetailsTab from './VideoDetailsTab';
import MetadataTab from './MetadtaTab';
import MonitoringTab from './MonitoringTab';

function TabsContentEvaluated ({
    selectedOverviewTab,
    videoInfo,
    labelsStat,
    monitoring,
    textStyle,
    setSelectedLabel,
    severityScore
}) {
    switch (selectedOverviewTab) {
        case 0:
            return (
                <StatusCards 
                    labelsStat={labelsStat}
                    setSelectedLabel={setSelectedLabel}
                    severityScore={severityScore}
                />
            );
            
        case 1:
            return (
                <VideoDetailsTab
                    videoInfo={videoInfo}
                    textStyle={textStyle}
                    severityScore={severityScore}
                />
            );
        case 2:
            return (
                <MetadataTab />
            );
        case 3:
            return (
                <MonitoringTab
                monitoring={monitoring}
                />
            );
        default:
            return null;
    }
}

export default TabsContentEvaluated;