import { Typography } from "@mui/material";

function CardTitle({text, color="textSecondary"}) {
    return (
        <Typography variant="h6" color={color}>
            {text}
        </Typography>
    )
}

export default CardTitle;