import React from 'react';
import { useState, useEffect } from 'react';
import { ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material/styles';
import Header from './components/Header';
import Navbar from './components/Navbar';
import { Outlet } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Footer from './components/Footer';
import Box from '@mui/material/Box';
import GridWrapper from './components/GridWrapper';
import { AuthProvider } from './contexts/AuthContext';
import { RestrictUnloadProvider } from './contexts/RestrictUnloadContext';


let ireckonuTheme = createTheme({
  typography: {
      fontFamily: 'Inter'
  },
});

ireckonuTheme = responsiveFontSizes(ireckonuTheme);

function App() {
  const [title, setTitle] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const parsedTitle = location.pathname.replace(/\W/g, ' ').trim();
    let finalTitle;

    if (parsedTitle === "") {
      finalTitle = "Dashboard";
    } else if (parsedTitle.startsWith("dashboard")) {
      finalTitle = "Dashboard";
    } else if (parsedTitle.startsWith("inspections")) {
      finalTitle = "Inspections";
    } else if (parsedTitle.startsWith("results")) {
      finalTitle = "Results";
    } else {
      finalTitle = parsedTitle.charAt(0).toUpperCase() + parsedTitle.slice(1);
    }

    setTitle(finalTitle);
  }, [location]);
  const ifLogin = (title === 'Login page')

  const [restrictUnload, setRestrictUnload] = useState(false);
  // Handle beforeunload event (reload or close tab)
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (restrictUnload){
        const message = 'Reloading the page may interrupt the process of video upload and validation. Are you sure you want to reload?';
        event.preventDefault();
        event.returnValue = message;
        return message;
      }
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [restrictUnload]);

  return (
    <ThemeProvider theme={ireckonuTheme}>
    <RestrictUnloadProvider restrictUnload={restrictUnload} setRestrictUnload={setRestrictUnload}>
    <AuthProvider>
      {ifLogin ?
        <Outlet /> 
        :
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
          <Grid container>
            <Navbar />
            <Header title={title} />
            <GridWrapper>
              <Outlet />
            </GridWrapper>
          </Grid>
          <Footer />
        </Box>
      }
    </AuthProvider>
    </RestrictUnloadProvider>
    </ThemeProvider>
    );
  };


export default App;
