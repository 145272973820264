import { Card, CardContent, Typography } from "@mui/material";

function EmptyMapCard({customText=null}) {
    return (
        <Card sx={{ 
            maxWidth: 600, 
            margin: '20px auto', padding: '16px',
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
            borderColor: '#ccc', borderWidth: 1, borderStyle: 'solid' 
            }}>
        <CardContent>
            {customText && (
                <Typography variant="body1" color="text.secondary">{customText}</Typography>
            )}
            <Typography variant="body1" color="text.secondary">
                If you want to see the location of the pipe on the map, please add coordinates of the start point and end point of the inspection to the metadata in the following format:
                <br />
                <strong>Label:</strong> StartPointLocation, <strong>Type:</strong> text, <strong>Value:</strong> 123456.78 876543.21
                <br />
                <strong>Label:</strong> EndPointLocation, <strong>Type:</strong> text, <strong>Value:</strong> 876543.21 123456.78
            </Typography>
        </CardContent>
        </Card>
    )
}

export default EmptyMapCard;