import CardWrapper from "./CardWrapper";
import TotalInspections from "./TotalInspections";
import TotalDefects from "./TotalDefects";
import SeverePipes from "./SeverePipes";

function KpiCards({analyticsData}) {
    return (
        <>
        <CardWrapper to="/inspections">
            <TotalInspections 
                totalInspections={analyticsData.totalInspections}
                evaluatedInspections={analyticsData.evaluatedInspections}
            />
        </CardWrapper>
        <CardWrapper to="/inspections">
            <TotalDefects 
                totalDefects={analyticsData.totalDefects}
                defectsPerInspection={analyticsData.defectsPerInspection}
            />
        </CardWrapper>
        <CardWrapper to="/inspections">
            <SeverePipes
                severeConditionPipes={analyticsData.severeConditionPipes}
                severePercentage={analyticsData.severePercentage}
            />
        </CardWrapper>
        </>
    )
}

export default KpiCards;