import { useContext, useMemo } from 'react';
import 'leaflet/dist/leaflet.css'
import { MapContainer, TileLayer, Polyline } from 'react-leaflet'
import { MetadataContext } from '../../contexts/MetadataContext';
import DataErrorCard from '../common/DataErrorCard';
import EmptyMapCard from './EmptyMapCard';
import { calculateCenter } from '../../utils/mapUtils';
import PipeInfoCard from './PipeInfoCard';
import MapPlaceholder from './MapPlaceholder';
import { getMapLineOptions } from '../../utils/mapUtils';

function InspectionMap({severityScore}) {
    const { inspectionCoordinates } = useContext(MetadataContext);
    if (inspectionCoordinates) {
        if (inspectionCoordinates.length > 0) {
            const center = calculateCenter(inspectionCoordinates);
            
            return (
                <MapContainer
                    center={center} 
                    zoom={17}
                    style={{ height: '280px', width: '40vw'}}
                >
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <Polyline pathOptions={getMapLineOptions(severityScore)} positions={inspectionCoordinates} >
                        <PipeInfoCard />
                    </Polyline>
                </MapContainer>
            )
        } else {
            return (
            <MapPlaceholder
                height="280px" 
                width="40vw" 
            >
                <EmptyMapCard/>
            </MapPlaceholder>
            );
        }
    } else {
        return (
        <MapPlaceholder
            height="280px" 
            width="40vw" 
        >
            <DataErrorCard transparent={true}/>
        </MapPlaceholder>
    );
    }
}

export default InspectionMap;