import { useState, useEffect } from 'react';
import { REQUEST_STATUS } from '../consts/requestStatus';

function useRequestDetections(videoId) {
    const [dataRequestStatus, setDataRequestStatus] = useState(REQUEST_STATUS.LOADING);
    const [videoInfo, setVideoInfo] = useState(null);
    const [videoName, setVideoName] = useState(null);
    const [labelsStat, setLabelsStat] = useState(null);
    const [videoWithPredictions, setVideoWithPredictions] = useState(null);
    const [monitoring, setMonitoring] = useState(null);
    const [data, setData] = useState(null);
    const [fpsValue, setFpsValue] = useState(null);
    const [videoEvaluated, setVideoEvaluated] = useState(true);
    const [severityScore, setSeverityScore] = useState(null);
    useEffect(() => {
        const fetchResultsInfo = async () => {
            try {
                const requestUrl = process.env.REACT_APP_GET_EVAL_RESULTS_URL;
                const response = await fetch(`${requestUrl}?video_id=${videoId}`);
                if (response.ok) {
                    const resultString = await response.text();
                    const resultArray = JSON.parse(resultString);
                    setVideoInfo(resultArray.info);
                    setVideoName(resultArray.name);
                    setVideoWithPredictions(resultArray.videoWithPredictions);
                    setMonitoring(resultArray.monitoring);
                    setFpsValue(resultArray.FPS);
                    setSeverityScore(resultArray.SeverityScore)
                    if (resultArray.info.Status === 'Evaluated') {
                        setLabelsStat(resultArray.labels_stat);
                        setData(resultArray.data);
                    } else {
                        setVideoEvaluated(false);
                    }
                    setDataRequestStatus(REQUEST_STATUS.SUCCESS);
                } else {
                    console.error('Error fetching results info:', response.statusText);
                    setDataRequestStatus(REQUEST_STATUS.FAILURE);
                }
            } catch (error) {
                console.error('Error fetching results info:', error.message)
                setDataRequestStatus(REQUEST_STATUS.FAILURE);
            }
        };
        setDataRequestStatus(REQUEST_STATUS.LOADING);
        fetchResultsInfo();
    }, [videoId]);

    return {
        videoInfo,
        videoName,
        labelsStat,
        monitoring,
        data,
        fpsValue,
        videoWithPredictions,
        videoEvaluated,
        dataRequestStatus,
        severityScore
    };
}

export default useRequestDetections;