import { Typography } from "@mui/material";

function CardValue({value, color='textPrimaty'}) {
    return (
        <Typography variant="h3" sx={{ fontWeight: 'bold', mb: 1 }} color={color}>
            {value}
        </Typography>
    )
}

export default CardValue;