import { useState, useEffect, useCallback } from 'react';
import { REQUEST_STATUS } from '../consts/requestStatus';
import fetchStatusTable from '../services/fetchStatusTable';


function useRequestStatusTable() {
    const [data, setData] = useState([]);
    const [statusOptions, setStatusOptions] = useState([]);
    const [tableRequestStatus, setTableRequestStatus] = useState(REQUEST_STATUS.LOADING);

    const refreshTable = useCallback(async () => {
        setTableRequestStatus(REQUEST_STATUS.LOADING);
        try {
            const response = await fetchStatusTable();
            const resultString = await response.text();
            const resultArray = JSON.parse(resultString);
            setData(resultArray.data);
            const flattenedStatusOptions = Object.entries(resultArray.statuses).map(([group, statuses]) => ({
                group,
                statuses,
            }));
            setStatusOptions(flattenedStatusOptions);
            setTableRequestStatus(REQUEST_STATUS.SUCCESS);
            
        } catch (error) {
            console.error('Error fetching data:', error);
            setTableRequestStatus(REQUEST_STATUS.FAILURE);
        }
    }, []);

    useEffect(() => {
        refreshTable();
    }, [refreshTable])

    return {
        data,
        tableRequestStatus,
        statusOptions,
        refreshTable
    }
}

export default useRequestStatusTable;