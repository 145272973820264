import 'leaflet/dist/leaflet.css'
import { MapContainer, TileLayer, Polyline } from 'react-leaflet'
import OverviewPipeInfoCard from './OverviewPipeInfoCard';
import { getMapLineOptions } from '../../utils/mapUtils';

function OverviewMap({data, center=[51.841861, 5.861058]}) {
    return (
        <MapContainer
            center={center} 
            zoom={14}
            style={{ 
                maxHeight: '75vh',
                minHeight: '50vh', 
                height: '60vh',
                width: 'auto',
                borderRadius: '8px'
            }}
        >
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {data.map((row, rowIndex) => (
                <Polyline id={row.Id} pathOptions={getMapLineOptions(row.SeverityScore)} positions={[row.StartPointLocation.split(' '), row.EndPointLocation.split(' ')]} >
                    <OverviewPipeInfoCard row={row} id={row.Id}/>
                </Polyline>
            ))}
            
        </MapContainer>
    )
        
}

export default OverviewMap;