import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer, CartesianGrid } from 'recharts';
import DataErrorCard from '../common/DataErrorCard';

function InspectionsChart({ data }) {
    if (data === null) {
      return (
        <Card sx={{ 
          boxShadow: 3,
          mx: 'auto',
          width: '100%',
          minWidth: 400,
        }}>
          <CardContent>
            <DataErrorCard />
          </CardContent>
        </Card>
      )
    }
    return (
        <Card sx={{ 
            boxShadow: 3,
            mx: 'auto',
            width: '100%',
            minWidth: 400,
          }}>
            <CardContent>
              <Typography variant="h6" color="textSecondary" sx={{ mb: 3 }}>
                Monthly Inspection Trend
              </Typography>
              <ResponsiveContainer width="100%" height={323}>
                <LineChart data={data} margin={{ top: 20, right: 30, left: 0, bottom: 5 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="month" />
                <YAxis />
                <Tooltip />
                <Line type="monotone" dataKey="inspections" stroke="#8884d8" strokeWidth={2} dot={{ r: 4 }} />
                </LineChart>
            </ResponsiveContainer>
        </CardContent>
        </Card>
    );
}

export default InspectionsChart;
