import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const Footer = () => {
  const textStyle = {marginLeft: '240px'}
  return (
    <Box
      sx = {{
      marginTop: "auto", 
      alignSelf: "flex-end",
      backgroundColor: '#9e9e9e', 
      color: '#fff', 
      padding: '10px', 
      textAlign: 'center',
      width: '100%',
      marginLeft: '240px'}}
      component="footer"
    >
      <Typography variant="body2" sx={textStyle}>
        Copyright ©2025 IRECKON CITY, All rights reserved.
      </Typography>
    </Box>
  );
};

export default Footer;