import 'leaflet/dist/leaflet.css'
import { Link } from 'react-router-dom';
import { Popup } from 'react-leaflet'
import { Typography, Box, Button } from '@mui/material';
import SeverityScoreCard from '../VideoInfoTabs/SeverityScoreCard';

function OverviewPipeInfoCard({
    row
}) {

    return (
        <Popup>
        <Box>
            {row.Id && (
                <Typography variant="h6" sx={{ color: 'primary.main', fontWeight: 'bold'}}>
                    ID: {row.Id}
                </Typography>
            )}
            <Typography variant="body1">
                    <>
                        <b>Inspection Date</b>: {row.InspectionDate ? row.InspectionDate : 'N/A'} <br/>
                    </>
                    <>
                        <b>Status</b>: {row.Status ? row.Status : 'N/A'} <br/>
                    </>
                    <>
                        <b>Severity Score</b>: {row.SeverityScore ? <SeverityScoreCard severityScore={row.SeverityScore}/> : 'N/A'} <br/>
                    </>
            </Typography>
            {row.Id && (
                <Box sx={{ display: 'flex', justifyContent: 'center'}}>
                    <Link to={`/results/${row.Id}`} style={{ textDecoration: 'none' }}>
                        <Button variant="text">Go to Inspection</Button>
                    </Link>
                </Box>
            )}
            
        </Box>
        </Popup>

    );
}

export default OverviewPipeInfoCard;