import React, { useContext, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import { CircularProgress } from '@mui/material';
import CustomTable from '../common/CustomTable';
import TableActionButton from './TableActionButton';
import CustomTablePagination from '../common/CustomTablePagination';
import { StatusTableContext } from '../../contexts/StatusTableContext';
import TableDeleteButton from './TableDeleteButton';
import { REQUEST_STATUS } from '../../consts/requestStatus';
import DataErrorCard from '../common/DataErrorCard';
import { formatDateTime, formatDate } from '../../utils/dateTimeUtils';
import SeverityScoreCard from '../VideoInfoTabs/SeverityScoreCard';
import { getStatusTableData } from '../../utils/statusTableUtils';

const StatusTable = () => {
    const { 
        nRows, setNRows, 
        page, setPage, 
        data, 
        refreshTable, 
        tableRequestStatus, 
        tableOrder, setTableOrder, 
        curSelectedStatuses, curTextSearch, curDateSearch,  
    } = useContext(StatusTableContext);
    const {totalRows, tableData} = getStatusTableData(data, page, nRows, curSelectedStatuses, tableOrder, curTextSearch, curDateSearch);
    useEffect(() => {
            if ((page - 1) * nRows >= totalRows) {
                setPage(1);
            } 
    }, [data, page, nRows, curSelectedStatuses, curTextSearch, curDateSearch])
    const columns = [
        { id: 'Id', label: 'Id', orderBy: true},
        { 
            id: 'Name', 
            label: 'Name',
            orderBy: true,
            render: (row) => (
                <Link
                    underline="hover"
                    component={RouterLink}
                    to={`/results/${row.Id}`}
                >
                    {row.Name}
                </Link>
            )
        },
        { 
            id: 'UploadTime', 
            label: 'Upload Time', 
            orderBy: true,
            render: (row) => (
                <>
                {formatDateTime(row.UploadTime)}
                </>
            )
        },
        { id: 'Status', label: 'Status', orderBy: true},
        { id: 'Username', label: 'Username', orderBy: true},
        { id: 'Place', label: 'Place', orderBy: true},
        { id: 'StreetName', label: 'Street Name', orderBy: true},
        { 
            id: 'InspectionDate', 
            label: 'Inspection Date', 
            orderBy: true,
            render: (row) => (
                <>
                {formatDate(row.InspectionDate)}
                </>
            )
        },
        { 
            id: 'SeverityScore', 
            label: 'Severity Score', 
            orderBy: true,
            render: (row) => (
                <SeverityScoreCard severityScore={row.SeverityScore} />
            )

        },
        { 
            id: 'Action', 
            label: 'Action', 
            render: (row) => (
                <TableActionButton
                    rowStatus={row.Status}
                    rowVideoId={row.Id}
                    refreshTable={refreshTable}
                />
            )
        },
        { 
            id: 'Delete', 
            label: 'Delete', 
            render: (row) => (
                <TableDeleteButton videoId={row.Id} videoName={row.Name} refreshTable={refreshTable}/>
            )
        }
    ];
    const tableMinWidth = 650
    if (tableRequestStatus === REQUEST_STATUS.FAILURE) {
        return (
            <DataErrorCard/>
        )
    }
    return (
            <>
            <CustomTablePagination 
                totalRows={totalRows} 
                rowsPerPage={nRows}
                setRowsPerPage={setNRows}
                page={page}
                setPage={setPage}
            />
            {tableRequestStatus === REQUEST_STATUS.LOADING ? (
                    <div style={{ textAlign: 'center', padding: '20px' }}>
                        <CircularProgress />
                    </div>
                ) : (
                    <>
                    <CustomTable
                        columns={columns}
                        data={tableData}
                        rowKey="VideoId"
                        tableMinWidth={tableMinWidth}
                        tableOrder={tableOrder}
                        setTableOrder={setTableOrder}
                    />
                    </>
                )}
            <CustomTablePagination 
                totalRows={totalRows} 
                rowsPerPage={nRows}
                setRowsPerPage={setNRows}
                page={page}
                setPage={setPage}
            />
            </>
    );
};

export default StatusTable;