import { Typography } from "@mui/material"

function CardText({text}) {
    return (
        <Typography variant="body2" color="textSecondary"  sx={{ fontWeight: 'medium' }}>
            {text}
        </Typography>
    )
}

export default CardText;