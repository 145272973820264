import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import UploadStepper from './components/UploadStepper/UploadStepper';
import VideoPage from './pages/VideoPage';
import LoginPage from './components/LoginPage';
import InspectionsPage from './pages/InspectionsPage';
import AnalyticsPage from './pages/AnalyticsPage';

ReactDOM.render(
    <BrowserRouter>
    <Routes>
        <Route path="/" element={<App />}>
            <Route index element={<AnalyticsPage />} />
            <Route path="dashboard" element={< AnalyticsPage />}></Route>
            <Route path="inspections" element={< InspectionsPage />}></Route>
            <Route path="upload-video" element={< UploadStepper />}></Route>
            <Route path="results/:videoId" element={< VideoPage />}></Route>
            <Route path="login-page" element={< LoginPage />}></Route>
        </Route>
    </Routes>
    </BrowserRouter>,
    document.getElementById('root')
);