import React from 'react';
import { 
  Grid, 
  Card, 
  CardContent,
  CardActionArea
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

function CardWrapper({children, to}) {
    const navigate = useNavigate();
    const handleClick = () => {
      if (to) navigate(to);
    };
    return (
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <Card sx={{ 
            height: '100%', 
            boxShadow: 3,
            // maxWidth: 350,
            mx: 'auto',
            width: '100%',
            minWidth: 230,
          }}
          >
          <CardActionArea sx={{ height: '100%' }} onClick={handleClick}>
            <CardContent sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>{children}</CardContent>
          </CardActionArea>
          </Card>
        </Grid>
    )
}

export default CardWrapper;